html {
  height: 100%;
}

body {
  margin: 0;
  background-color: #f2f5f8 !important;
  min-width: 1280px !important;
  height: 100%;
}

div#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

form {
  padding-top: 5px;
}

* {
  font-family: 'Muli', 'sans-serif';
}